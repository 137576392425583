@import "../../../../styles/mixins";

.container {
  
  p {
    margin-top: var(--sp-md);
  }
  
  ul {
    padding-left: 0;
  }
  
  li {
    margin-top: var(--sp-xs);
  }
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.techHeader {
  @include header-line(-12.5px, 300px, 105px, var(--text-color-tertiary));
  @include tablet {
    @include header-line(-12.5px, 60%, 105px, var(--text-color-tertiary));
  }
  @include mobile {
    @include header-line(-12.5px, 45%, 105px, var(--text-color-tertiary));
  }
}

.techStackList {
  h4 {
    margin-bottom: 0;
  }
}

.aspectRatioWrapper {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  display: flex;
  align-items: baseline;
  justify-items: center;
}

.diamond {
  position:relative;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  transform: rotate(45deg) scale(.65) translate(20px, -20px);
  border: 7.5px solid var(--text-color-primary);
  border-radius: 25px;
  box-shadow: 0 0 10px 10px var(--logo-color-secondary);
  @include tabletAndDown {
    transform: rotate(45deg) scale(.5);
  }
  .profile {
    transform: rotate(-45deg) scale(1.5) translateY(4%);
    max-width: 100%;
    height: auto;
    width: 100%;
  }
}