@import '../../../../../styles/mixins';

.techList {
  margin-top: var(--sp-md);
  li {
    margin-left: 0.9375rem;
    position: relative;
    @include font-ternary;
    &::before {
      content: "•";
      position: absolute;
      left: -0.9375rem;
      top: 0.35rem;
      color: var(--logo-color-secondary);
      line-height: 12px;
    }
  }
}