@import "../../../../styles/mixins";

.heroContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: calc(100vh); // Adjust for padding and navbar height
  background-color: var(--background-color-primary);
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5)), url("../../../../../assets/images/slc-background-low-res.jpg");
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.5)), url("../../../../../assets/images/slc-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--text-color-primary);
  @include tablet {
    background-position: center;
  }
  @include mobile {
    padding: 1rem;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  top: -50px;
}

.name {
  font-size: 3.5rem;
  @include tabletAndDown {
    margin-top: var(--sp-sm);
  }
  @include tablet {
    font-size: 3rem;
  }
  @include mobile {
    font-size: 2.5rem;
  }
}

.location {
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-sans-serif);
  font-size: 1.5rem;
}

.description {
  font-size: 1.1rem;
  max-width: 475px;

}

.titleContainer {
  display: flex;
  align-items: baseline;
}

.heroLogoContainer {
  @include mobile {
    display: none;
  }
}

.heroLogo {
  display: inline-block;
  width: 25px;
  height: auto;
  margin-left: 16px;
  position: relative;
  top: 4px;
}

.button {
  display: inline-block;
}

@include tablet {
  .heroContainer {
    left: 0;
  }
}
