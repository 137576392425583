//for scss features that are not supported by css variables
$breakpoint-desktop: 64rem;
$breakpoint-tablet: 48rem;
$breakpoint-lg-mobile: 35rem;
$breakpoint-mobile: 30rem;

:root {
  // breakpoints
  --breakpoint-desktop: 64rem;
  --breakpoint-tablet: 48rem; 
  --breakpoint-lg-mobile: 35rem;
  --breakpoint-mobile: 30rem;

  // container styles
  --container-max-width: 75rem;
  --container-padding-desktop: 2.5rem;
  --container-padding-tablet: 2.5rem;
  --container-padding-mobile: 1.2rem; 

  // ccs grid gutters
  --gutter-xs: 0.5rem;
  --gutter-sm: 1rem;
  --gutter-md: 1.5rem;
  --gutter-lg: 2rem;
  --gutter-xl: 2.5rem;

  // logo colors
  --logo-color-primary: #293462;
  --logo-color-secondary: #d61c4e;
  --logo-color-tertiary: #feb139;

  // Colors
  --background-color: #0d1424;
  --nav-and-ft-bg-clr: #141e33;


  // Button colors
  --button-background-color: var(--logo-color-primary);
  --button-text-color: #ffffff; // A light color for contrast
  --button-hover-color: #445c8e; // A lighter shade of the primary color
  --button-active-color: #0d1424; // A darker shade of the primary color

  // Secondary button colors
  --button-secondary-background-color: var(--logo-color-secondary);
  --button-secondary-hover-color: #c91642; // A darker shade of the secondary color
  --button-secondary-active-color: #b11238; // An even darker shade of the secondary color

  // Font Families
  --font-family-sans-serif: 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
  --font-family-display: 'THICCCBOI', 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;

  // Font Weights
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-semi-bold: 500;
  --font-weight-bold: 600;
  --font-weight-extra-bold: 700;

  // Text colors
  --text-color-primary: #f0f0f0;
  --text-color-secondary: var(--logo-color-secondary);
  --text-color-tertiary: var(--logo-color-tertiary);
  

  // Spacing
  --sp-0: 0;
  --sp-xs: 0.5rem;
  --sp-sm: 1rem;
  --sp-md: 2rem;
  --sp-lg: 3rem;
  --sp-xl: 4rem;
  --sp-xxl: 6rem;
  --sp-xxxl: 8rem;
}