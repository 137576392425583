@import '../../../styles/utilities';

.mobileMenuContainer {
  display: none;
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  z-index: 20;
  background-color: var(--nav-and-ft-bg-clr);
  overflow-y: scroll;
  transition: right 0.5s ease-in-out;
  overflow: hidden;

  ul {
    margin-top: 4em;
  }
  li {
    cursor: pointer;
    font-size: 1.25rem;
    a.active {
      color: var(--logo-color-secondary);
    }
  }
  @include tabletAndDown {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 350px;
  }
  @include mobile {
    width: 100%;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--sp-sm);
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(-ft-p);
  &:hover {
    color: var(--logo-color-secondary);
  }

  .closeIcon {
    display: block;
    width: 2rem;
    height: 2rem;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: .25em;
      background-color: var(--logo-color-secondary);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 1.3em;
  li {
    margin: var(--sp-sm) 0;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
}

.isMobileMenuOpen {
  right: 0;

  li {
    opacity: 1;
    transform: translateX(0);
  }
}
