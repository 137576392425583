// Basic typography settings
body {
  font-family: var(--font-family-sans-serif);
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--background-color);
}

// Link styles
a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease;

  &:hover {
    color: var(--secondary-color);
  }
}

// Other global elements
ul, ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

.button {
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-bold);
  font-size: 1rem; // 16px
  padding: 12px 24px; // Adjust the padding to your preference
  background-color: var(--button-secondary-background-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px; // Adjust the border radius to your preference
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: var(--button-secondary-hover-color);
  }

  &:active {
    background-color: var(--button-secondary-active-color);
  }

  &-outline {
    background-color: transparent;
    color: var(--button-secondary-background-color);
    border: 1px solid var(--button-secondary-background-color);

    &:hover {
      background-color: var(--button-secondary-background-color);
      color: var(--button-text-color);
    }

    &:active {
      background-color: var(--button-secondary-active-color);
      color: var(--button-text-color);
    }
  }

  // Responsive adjustments for tablet devices
  @include tabletAndDown {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  // Responsive adjustments for mobile devices
  @include mobile {
    padding: 8px 16px;
  }
}
