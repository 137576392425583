@import './variables';

// Import variables
@import './variables';

// Desktop styles
@mixin desktop {
  @media only screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}

// Tablet styles
@mixin tablet {
  @media only screen and (min-width: $breakpoint-mobile + 1) and (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin tabletAndDown {
  @media only screen and (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin lgMobile {
  @media only screen and (max-width:$breakpoint-lg-mobile) {
    @content;
  }
}

// Mobile styles
@mixin mobile {
  @media only screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin header-line($top-offset, $line-width, $margin-left, $line-color) {
  &::after {
    content: "";
    display: block;
    position: relative;
    top: $top-offset;
    width: $line-width;
    height: 1px;
    margin-left: $margin-left;
    background-color: $line-color;
  }
}


@mixin font-ternary {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  line-height: 1.5;
}