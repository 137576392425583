.footer {
  position: sticky;
  bottom: 0;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--nav-and-ft-bg-clr);
  padding: 1rem;
  width: 100%;
  z-index: 100;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  color: var(--text-color-primary);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.5rem;
  &:hover {
    color: var(--text-color-secondary);
  }
}
