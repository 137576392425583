.errorPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorBtn {
  display: block;
  margin-top: 1rem;
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
}