@import '../../../../styles/mixins';

.eduCertsContainer {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.certTitle {
  @include header-line(-12.5px, 300px, 180px, var(--text-color-tertiary));
  @include lgMobile {
    @include header-line(-12.5px, 50%, 165px, var(--text-color-tertiary));
  }
  @include mobile {
    @include header-line(-12.5px, 45%, 140px, var(--text-color-tertiary));
  }
}