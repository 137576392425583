@import '../../../../styles/mixins';

:root {
  --work-panel-transition-setting: opacity .75s;
}

.workContainer {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.tabList {
  display: flex;
  gap: 16px;
  padding-left: 0;
  @include tabletAndDown {
    flex-wrap: wrap;
    gap: 8px;
  }
}

.tab {
  text-align: center;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid var(--logo-color-primary);
  font-weight: var(--font-weight-normal);
  width: 125px;
  outline: none;
  padding: 10px;
  transition: background-color .5s ease-in-out;

  &.active {
    font-weight: var(--font-weight-semi-bold);
    border-bottom: 2px solid var(--logo-color-secondary);
    background-color: var(--logo-color-primary);
    transition: background-color .5s ease-in-out;
  }

  @include tabletAndDown {
    border: 1px solid var(--logo-color-primary);
    &.active {
      border: 1px solid var(--logo-color-secondary);
    }
    border-radius: 4px;
  }
  @include mobile {
    border: 1px solid var(--logo-color-primary);
  }
}

.tabPanel {
  opacity: 0;
  transition: var(--work-panel-transition-setting);

  &.active {
    opacity: 1;
    padding: 1rem;
    transition: var(--work-panel-transition-setting);
  }
}
