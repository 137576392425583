.contactContainer {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 100vh;
}

.contactContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
}

.title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.link {
  &:hover {
    color: var(--text-color-primary);
  }
}