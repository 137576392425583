@import '../../../styles/utilities';

.desktopMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  list-style: none;
  li {
    margin-left: 1rem;
    cursor: pointer;
    a:not(.buttonLink):hover{
      color: var(--logo-color-secondary);
    }

    .active {
      color: var(--logo-color-secondary);
    }
  }
}  
@include tabletAndDown {
  .desktopMenu {
    display: none;
  }
}