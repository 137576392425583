.recommendationsSpacing {
  margin-top: 10rem;
}
.title {
  text-align: center;
}

.slide {
  display: flex;
  justify-content: space-around;
}