@import "../../../../../styles/mixins";

.certificationDescriptionContainer {
  max-width: 450px;
  margin-left:auto;
  &.isOdd {
    order: 1;
    text-align: right;
    @include lgMobile {
      order: 0;
      text-align: center;
    }
  }
}

.certificationDescription {
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 10px 11px 4px rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  background-color: var(--logo-color-primary);
  padding: 25px;
  @include font-ternary;
  font-size: 1rem;
}

.certificationButton {
  display: block;
  width: 180px;
  text-align: center;
  color: var(--text-color-primary);
  
  &.pullLeft {
    margin-left: auto;
    @include lgMobile {
      margin-left: auto;
    }
  }

  @include lgMobile {
    margin-right: auto;
    margin-left: auto;
  }
}

.txtAlgEven {
  text-align: left;
}

.txtAlgOdd {
  text-align: right;
}

@include lgMobile {
  .txtAlgEven, .txtAlgOdd {
    text-align: center;
  }
}

.certImg {
  max-width: 200px;
  margin: 0 auto;
}

