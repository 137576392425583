@import '../../../../../styles/mixins';

.positionContainer { 
  min-height: 361px;
}

.companyImg {
  border-radius: 4px;
}

.descriptionList {
  max-width: 450px;
  li {
    margin-left: 0.9375rem;
    @include mobile {
      margin-left: 0;
    }
    position: relative;
    @include font-ternary;
    &::before {
      content: "•";
      position: absolute;
      left: -0.9375rem;
      top: 0.35rem;
      color: var(--logo-color-secondary);
      line-height: 12px;
    }
    &:not(:first-of-type) {
      margin-top: var(--sp-sm);
    }
  }
}
