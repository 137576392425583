@import '../../styles/utilities';
.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky; 
  top: 0;
  background-color: var(--nav-and-ft-bg-clr);
  z-index: 1000;
  padding: 1rem 2rem;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
  padding: .8rem 2rem;
}

.mobileMenuIcon, .mobileMenuIconBar {
  display: none;
  width: 28px;
  @include tabletAndDown {
    display: block;
  }
}

.mobileMenuIcon {
  height: 1.75rem;
  position: relative;
  top: -1px;
  cursor: pointer;
  //reset button styles
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.mobileMenuIconBar { 
  @include tabletAndDown {
    background-color: var(--text-color-primary);
    border-radius: 4px;
    height: 0.25rem;
    &:nth-of-type(2), &:nth-of-type(3) {
      margin-top: 0.375rem;
    }
  }
}


.mobileMenuUnderlay {

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: translateX(-100%);
  
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  transition: opacity 0.2s ease-in-out;
  &.active {
    transform: translateX(0);
    opacity: 1;
  }
  &.underlayPosHolder {
    transform: translateX(0);
  }
    @include tabletAndDown {
    display: block;
  }
}

.mobileMenuButton {
  display: none;
  @include tabletAndDown {
    display: block;
  }
}


.logoLink {
  align-self: self-end;
  .logo {
    margin-right: auto;
    position: relative;
    max-height: 50px;
    cursor: pointer;
    top: 2px
  }
}