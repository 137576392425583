@import '../../../../../styles/mixins';

.recommendationContainer {
  flex-basis: 45%;
  //have to nest for specificity
  //to override carousel styles
  .header {
    .img{
      border-radius: 50%;
      border: 4px solid var(--logo-color-secondary);
      display: block;
      margin: 0 auto;
      @include desktop {
        max-width: 40%;
      }
      @include tablet {
        max-width: 75%;
      }

      @include mobile {
        max-width: 80%;
      }
    }

  }

  .body {
    blockquote {
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      margin: 0 auto;
      @include desktop {
        max-width: 20.315em;
      }
      @include tablet {
        max-width: 100%;
      }
      @include mobile {
        max-width: 100%;
      }
    }
  }
  @include desktop {
    flex-basis: 50%;
  }
  @include tablet {
    flex-basis: 40%;
  }

  @include mobile {
    flex-basis: 85%;
  }
}