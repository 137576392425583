// Import your mixins here
@import 'mixins';

// Typography styles
h1 {
  @include mobile {
    font-size: 2rem; // 32px
  }
  @include tablet {
    font-size: 2.25rem; // 36px
  }
  @include desktop {
    font-size: 2.5rem; // 40px
  }
}

h2 {
  @include mobile {
    font-size: 1.5rem; // 24px
  }
  @include tablet {
    font-size: 1.75rem; // 28px
  }
  @include desktop {
    font-size: 2rem; // 32px
  }
}

h3 {
  @include mobile {
    font-size: 1.25rem; // 20px
  }
  @include tablet {
    font-size: 1.5rem; // 24px
  }
  @include desktop {
    font-size: 1.75rem; // 28px
  }
}

h4 {
  @include mobile {
    font-size: 1rem; // 16px
  }
  @include tablet {
    font-size: 1.25rem; // 20px
  }
  @include desktop {
    font-size: 1.5rem; // 24px
  }
}

h5 {
  @include mobile {
    font-size: 0.875rem; // 14px
  }
  @include tablet {
    font-size: 1rem; // 16px
  }
  @include desktop {
    font-size: 1.25rem; // 20px
  }
}

h6 {
  @include mobile {
    font-size: 0.75rem; // 12px
  }
  @include tablet {
    font-size: 0.875rem; // 14px
  }
  @include desktop {
    font-size: 1rem; // 16px
  }
}

ul, ol {
  @include mobile {
    font-size: 0.875rem; // 14px
  }
  @include tablet {
    font-size: 1rem; // 16px
  }
  @include desktop {
    font-size: 1rem; // 16px
  }
}

h1, h2, h3, h4, h5, h6, ul, ol {
  font-family: var(--font-family-display);
  font-weight: var(--font-weight-bold);
  line-height: 1.25;
  color: var(--text-color-primary);
  margin-top: 1.5em; // Add a top margin
  margin-bottom: 1em; // Add a bottom margin
}

h1, h2 {
  font-weight: var(--font-weight-extra-bold);
}

// Set the top margin of the first element to 0
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
ul:first-child,
ol:first-child {
  margin-top: 0;
}

p {
  letter-spacing: .005rem;
  font-family: var(--font-family-sans-serif);
  font-weight: var(--font-weight-normal);
  font-size: 1.25rem; // 16px
  line-height: 1.5;
  color: var(--text-color-primary);

  @include mobile {
    font-size: 1rem; // 16px
  }

  @include tablet {
    font-size: 1.125rem; // 18px
  }

  @include desktop {
    font-size: 1.25rem; // 20px
  }
}


ul, ol {
  font-size: 1rem; // 16px
  padding-left: 2em; // Add some left padding for better indentation
}

blockquote {
  font-style: italic;
  color: var(--text-color-primary);
}

code, pre {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
